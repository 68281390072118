.why-I-do {
    text-align: justify;
    margin-block: 2rem;
    &__title {
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 1.5rem;
        h1 {
            font-size: calc(2.375rem + 1.5vw);
            margin-bottom: 0px;
        }
        .fw-bold {
            padding-left: 1rem;
            margin-top: -0.5rem;
        }
    }
    &__article {
        color: var(--secondary-color);
        padding-inline: 9%;
        line-height: 1;
        font-size: 12px;
        display: flex;
        align-items: center;
    }
    &__image {
        img {
            width: 100%;
            height: auto;
        }
    }
}
.how-I-do {
    text-align: justify;
    margin-block: 2rem;
    background: white;
    margin-inline: 1.5rem;
    border-radius: 10px;
    &__title {
        color: var(--primary-color);
        text-align: center;
        margin-block: 1.5rem;
        h1,
        b {
            font-size: calc(2rem + 1.5vw);
            margin-bottom: 0px;
        }
    }
    &__article {
        color: var(--secondary-color);
        padding-inline: 9%;
        line-height: 1;
        p {
            font-size: 12px;
        }
    }
    &__image {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        img {
            width: 60%;
            height: auto;
        }
    }
}
.what-I-do {
    text-align: justify;
    margin-top: 2rem;
    position: relative;
    &__image-background {
        width: 100%;
        position: absolute;
        max-height: 100%;
    }
    .row {
        position: inherit;
    }
    &__title {
        color: var(--green-color);
        text-align: center;
        margin-block: 1.5rem;
        .fw-bold {
            margin-top: -0.5rem;
            font-size: calc(3rem + 1.5vw);
            margin-bottom: 0px;
        }
        h1 {
            font-size: calc(3rem + 1.5vw);
            margin-bottom: 0px;
        }
    }
    &__article {
        color: white;
        padding-inline: 15% !important;
        line-height: 1;
        p {
            font-size: 12px;
        }
    }
    &__section-background {
        // background-image: linear-gradient(to bottom, rgba(45,49,250,0), rgba(45,49,250,1));
        background-image: linear-gradient(to bottom, rgba(45, 49, 250, 0), rgba(45, 49, 250, 1)),
            url(../assets/images/computer-what-i-do.jpeg);
        // background-image: linear-gradient(to bottom, rgba(45,49,250,0), rgba(45,49,250,1));
        background-repeat: no-repeat, no-repeat;
        background-size: cover, cover;
    }
}

/* -------------------------------------------------------------------------------------------------- MEDIDAS RESPONSIVE ------------------------------------------------------------------------------------------------------ */
@media all and (min-width: 768px) {
    .why-I-do {
        &__article {
            p {
                font-size: 1rem;
            }
            padding-inline: 6% !important;
        }
    }

.how-I-do {
    &__title {
        h1,
        b {
            font-size: calc(2.375rem + 1.5vw);
        }
    }
    &__image {
        height: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
}
.what-I-do {
    &__image-background {
        padding-block: 2rem;
    }
    &__title {
        h1:not(.fw-bold) {
            margin-left: -5rem;
        }
    }
    &__article {
        display: flex;
        align-items: center;
        padding-inline: 2% !important;
    }
    &__section-background {
        padding-block: 3rem;
    }
}
}

@media all and (min-width: 1024px) {
    .why-I-do {
        margin-block: 4rem;
        &__title {
            h1 {
                font-size: calc(3.375rem + 1.5vw);
            }
        }
        &__article {
            p {
                font-size: 1.3rem;
            }
            padding-inline: 4% !important;
        }
    }

    .how-I-do {
        &__title {
            h1,
            b {
                font-size: calc(3.375rem + 1.5vw);
            }
        }
        &__article {
            p {
                font-size: 1.3rem;
            }
        }
        &__image {
            img {
                width: 80%;
            }
        }
    }
    .what-I-do {
        &__image-background {
            padding-block: 2rem;
        }
        &__title {
            h1:not(.fw-bold) {
                margin-left: -10rem;
            }
            h1, .fw-bold {
                font-size: calc(4.375rem + 2.5vw);
            }
        }
        &__article {
            padding-inline: 3% !important;
            p {
                font-size: 1.3rem;
            }
        }
        &__section-background {
            padding-block: 5rem;
        }
    }
}
@media all and (min-width: 1440px) {
    .why-I-do {
        margin-block: 5rem;
        &__title {
            h1 {
                font-size: calc(4rem + 1vw)
            }
        }
        &__article {
            p {
                font-size: calc(1.6rem + 0.1vw);
            }
            padding-inline: 3% !important;
        }
    }

    .how-I-do {
        &__title {
            h1,
            b {
                font-size: calc(4rem + 1vw);
            }
        }
        &__article {
            padding-inline: 12%;
            p {
                font-size: calc(1.6rem + 0.1vw);
            }
        }
        &__image {
            img {
                width: 75%;
            }
        }
    }
    .what-I-do {
        &__title {
            h1:not(.fw-bold) {
                margin-left: -12rem;
            }
            h1, .fw-bold {
                font-size: calc(5rem + 1.5vw);
            }
        }
        &__article {
            padding-inline: 2% !important;
            p {
                font-size: calc(1.6rem + 0.1vw);
            }
        }
        &__section-background {
            padding-block: 7rem;
        }
    }
}