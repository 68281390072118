.personal-info {
    text-align: justify;
    /* margin-top: 2rem; */
    position: relative;
    padding: 0px !important;
    &__section {
        width: 100%;
        padding: 5rem 0rem 3rem 0rem;
    }
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../assets/images/computer.jpeg);
    background-position: center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    &__image-background {
        width: 100%;
        position: absolute;
        height: 100%;
        object-fit: cover;
    }
    &__title {
        z-index: 2;
        color: white;
        h1 {
            font-weight: 800;
            font-size: calc(2rem + 1.5vw);
            line-height: 2.3rem;
        }
    }
    &__container {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__article {
        &__invisible {
            z-index: 2;
            color: white;
            padding-top: 1rem;
            font-size: 12px;
            text-align: justify;
            line-height: 1;
            display: none;
        }
        &__visible {
            z-index: 2;
            color: white;
            padding-top: 1rem;
            font-size: 12px;
            text-align: justify;
            line-height: 1;
        }
    }

    .card {
        border: none;
        width: 100%;
        border-radius: 15px;
        .card-body {
            display: flex;
            justify-content: center;
        }
        .card-body img {
            width: 33%;
        }
    }
}
// &__section-background {
//     // background-image: linear-gradient(to bottom, rgba(45,49,250,0), rgba(45,49,250,1));
//     background-image: linear-gradient(to bottom, rgba(45, 49, 250, 0), rgba(45, 49, 250, 1)),
//         url(../assets/images/computer-what-i-do.jpeg);
//     // background-image: linear-gradient(to bottom, rgba(45,49,250,0), rgba(45,49,250,1));
//     background-repeat: no-repeat, no-repeat;
//     background-size: cover, cover;
// }
.skills {
    padding-block: 2rem !important;
    &__container {
        background-color: var(--third-color);
        border-radius: 10px;
        padding: 1rem !important;
        color: white;
        justify-content: center;
        display: flex;
        flex-direction: column;
        &__section {
            display: flex;
            flex-direction: column-reverse;
            margin-block: 1rem;
            &__sons {
                display: flex;
                flex-direction: column-reverse;
                margin-left: 3rem;
                border-left: 2px solid white;
            }
            .label-skill {
                padding-left: 1rem;
                margin: 0px;
                margin-block: 1rem;
                width: 100%;
                font-size: calc(1rem + 0.6vw);
                .progress {
                    height: 1.5rem;
                    width: 85px;
                }
                svg {
                    margin-left: 0.5rem;
                    height: 1rem;
                }
            }
            .title-label-skill {
                font-weight: bold;
                font-size: calc(1rem + 0.6vw);
                svg {
                    margin-left: 0.5rem;
                    height: 1rem;
                }
            }

            &__hr {
                width: 15px;
                color: white;
                height: 1px;
                background-color: white;
                opacity: 1;
                border: 1px solid;
                vertical-align: middle;
                margin-left: 2px;
            }
        }
    }
}

.experience {
    padding: 2rem !important;
    &__section-container {
        border-radius: 10px;
        background-color: white;
        padding: 1rem;
        margin-block: 1rem;
        h3 {
            padding-left: 1rem;
            color: var(--primary-color);
            font-weight: 700;
        }
        // experience__section-container__experience-list__internal-row

        &__experience-list {
            color: black;
            line-height: 1.1;
            margin-block: 1rem;
            &__internal-row {
                display: flex;
                p {
                    width: 35%;
                }
                span {
                    width: 65%;
                    text-align: center;
                }
            }
            span,
            b,
            p,
            a {
                color: black;
                text-decoration: none;
            }
            // span {
            //     display: flex;
            //     flex-direction: column;
            //     span{
            //         display: flex;
            //         flex-direction: row;
            //         // align-items: center;
            //         b{
            //             width: 20%;
            //         }
            //     }
            // }
            .badge-date {
                background: #cce4fc !important;
                margin: 0.4rem;
                font-weight: 100;
                white-space: break-spaces;
            }
            .badge-company {
                background: var(--primary-color) !important;
                margin: 0.4rem;
                white-space: break-spaces;
                a {
                    color: white !important;
                    font-weight: 100;
                }
            }
        }
    }
}

@media all and (min-width: 768px) {
    .personal-info {
        &__section {
            padding: 5rem 0rem 5rem 0rem;
        }
        &__article {
            &__visible {
                display: none;
            }
            &__invisible {
                display: block;
                p {
                    font-size: 1rem;
                }
            }
        }
        .card {
            width: 70%;
            border-radius: 15px;
        }
    }
    .skills {
        padding-block: 2rem !important;
        &__container {
            border-radius: 15px;
            &__section {
                &__sons {
                    margin-left: 2.5rem;
                }
                .label-skill {
                    .progress {
                        width: 120px;
                    }
                    svg {
                        height: 1.5rem;
                    }
                }
                .title-label-skill {
                    svg {
                        height: 1.5rem;
                    }
                }
            }
        }
    }
    .experience {
        &__section-container {
            h3 {
                padding-left: 0.6rem;
                font-size: calc(1.3rem + 0.6vw);
            }
            &__experience-list {
                font-size: 1.1rem;
                span,
                b,
                p,
                a {
                    font-size: 1.1rem;
                }
                margin-block: 1rem;
                &__internal-row {
                    display: flex;
                    p {
                        width: 35%;
                    }
                    span {
                        width: 65%;
                    }
                }
            }
        }
    }
}
@media all and (min-width: 1024px) {
    .personal-info {
        &__section {
            padding: 6rem 0rem 6rem 0rem;
        }
        &__title {
            h1 {
                font-size: calc(2.5rem + 1.5vw);
                line-height: 2.5rem;
            }
            h4 {
                font-size: calc(1.8rem + 0.3vw);
            }
        }
        &__article {
            &__visible {
                display: none;
            }
            &__invisible {
                display: block;
                p {
                    font-size: 1.3rem;
                }
            }
        }
        .card {
            width: 65%;
            border-radius: 20px;
        }
    }

    .skills {
        padding-block: 4rem !important;
        &__container {
            border-radius: 20px;
            padding: 2rem !important;
            &__section {
                &__sons {
                    margin-left: 3.5rem;
                }
                .label-skill {
                    .progress {
                        width: 140px;
                    }
                    svg {
                        height: 1.5rem;
                    }
                }
                .title-label-skill {
                    svg {
                        height: 1.5rem;
                    }
                }
            }
        }
    }
    .experience {
        padding-block: 4rem !important;
        padding-inline: 2rem !important;
        &__section-container {
            border-radius: 20px;
            padding: 2rem;
            margin-block: 1.5rem;
            h3 {
                padding-left: 0.6rem;
                font-size: calc(1.6rem + 0.6vw);
            }
            &__experience-list {
                margin-block: 1.5rem;
                font-size: 1.3rem;
                span,
                b,
                p,
                a {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
@media all and (min-width: 1440px) {
    .personal-info {
        padding-block: 2rem;
        &__section {
            padding: 6rem 0rem 6rem 0rem;
        }
        &__title {
            h1 {
                font-size: calc(2.6rem + 1.5vw);
                line-height: 4.8rem;
            }
            h4 {
                font-size: calc(2rem + 1vw);
            }
        }
        &__article {
            &__visible {
                display: none;
            }
            &__invisible {
                display: block;
                p {
                    font-size: calc(1.4rem + 0.5vw);
                }
            }
        }
        .card {
            width: 80%;
            border-radius: 30px;
        }
    }

    .skills {
        padding-block: 4rem !important;
        &__container {
            border-radius: 20px;
            padding: 2rem !important;
            &__section {
                &__sons {
                    margin-left: 4.5rem;
                }
                &__hr {
                    width: 30px;
                    height: 2px;
                    border: 2px solid;
                    margin-left: 5px;
                }

                .label-skill {
                    font-size: calc(1.2rem + 0.6vw);
                    margin-block: 1.5rem;
                    .progress {
                        width: 180px;
                        height: 2rem;
                    }
                    svg {
                        height: 1.5rem;
                    }
                }
                .title-label-skill {
                    font-size: calc(1.2rem + 0.6vw);
                    svg {
                        height: 1.5rem;
                    }
                }
            }
        }
    }
    .experience {
        &__section-container {
            h3 {
                font-size: calc(1.6rem + 1.6vw);
            }
            &__experience-list {
                font-size: calc(1.3rem + 0.3vw);
                span,
                b,
                p,
                a {
                    font-size: calc(1.3rem + 0.3vw);
                }
            }
        }
    }
}
@media all and (min-width: 1900px) {
    .personal-info {
        .card {
            border-radius: 40px;
        }
    }
}

//------------------------------------------------------------------------------------------------------------
.self-accordion {
    &__item {
        &__selector {
        }
    }
}
