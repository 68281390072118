/* --------------------------------------- INICIO CONTENIDO HEADER COMPONENT ------------------------------------------- */
.header {
    background-color: var(--primary-color);
    position: sticky;
    display: flex;
    justify-content: center;
    padding-block: 1rem;
    height: 4rem;
    z-index: 100;
    top: 0px;
    a {
        width: 25%;
        align-self: center;
        height: auto;
    }
    &__menu-large {
        display: none;
    }
    &__icon-bars {
        color: white;
        position: absolute;
        right: 10px;
        top: calc(50% - 1rem);
        height: 2rem;
        width: 2rem;
    }
    #offcanvasLanding {
        background-color: var(--primary-color);
        height: fit-content;
        margin-top: 4rem;
        .offcanvas-title {
            color: white !important;
        }
        .nav-item {
            justify-content: center;
            padding-block: 1.2rem;
            border-top: 1px solid var(--green-color);
            text-align: center;
            width: 80%;
            margin-inline: auto;
            cursor: pointer;
            a {
                color: white;
                font-size: 1.2rem;
            }
            .dropdown-toggle {
                width: 50%;
                margin: auto;
                img {
                    width: 3rem;
                    height: 3rem;
                    min-width: 3rem;
                }
            }
            .dropdown-menu {
                .dropdown-item {
                    justify-content: center;
                    color: var(--primary-color) !important;
                    width: 100%;
                    &.ACTIVE {
                        background-color: var(--primary-color);
                        color: white !important;
                    }
                }
            }
        }
        .nav-item:first-child {
            border-top: none;
        }
        .offcanvas-header {
            height: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
        h5,
        h6 {
            color: var(--color-primary);
        }
    }
}

.shirt-item {
    display: flex;
    a {
        color: var(--green-color) !important;
    }
    .nav-item--shirt-img {
        width: 25px;
        margin-left: 6px;
        height: 25px;
    }
}

.carousel-item {
    img {
        height: 20rem;
    }
}
#banner1 {
    background-image: url("./../assets/images/bann1_16-9.png");
    height: 20rem;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}
#banner2 {
    background-image: url("./../assets/images/bann2_16-9.png");
    height: 20rem;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}
/* --------------------------------------- FIN  CONTENIDO HEADER COMPONENT ------------------------------------------- */

/* --------------------------------------- INICIO CONTENIDO FOOTER COMPONENT ------------------------------------------- */
footer {
    background-color: var(--secondary-color);
    padding: 2rem;
    .social-media {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
            width: 0.7rem;
            height: 0.7rem;
            background-color: white;
            border-radius: 6px;
            color: var(--secondary-color);
            padding: 5px;
            cursor: pointer;
        }
    }
    .logo {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        img {
            width: 70%;
        }
    }
    .article {
        color: white;
        margin-bottom: 1rem;
        padding-inline: 1.5rem;
        h6 {
            font-size: calc(1rem + 1.5vw);
            color: var(--green-color);
        }
        p {
            font-size: 12px;
            text-align: justify;
            line-height: 1;
        }
    }
}

/* --------------------------------------- FIN  CONTENIDO FOOTER COMPONENT ------------------------------------------- */

/* -------------------------------------------------------------------------------------------------- MEDIDAS RESPONSIVE ------------------------------------------------------------------------------------------------------ */
@media all and (min-width: 768px) {
    .header {
        justify-content: start;
        height: 6rem;
        &__logo {
            align-self: auto;
            width: 20% !important;
        }

        &__icon-bars {
            display: none;
        }
        &__menu-large {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            list-style: none;
            margin-bottom: 0px;
            color: white;
            a {
                color: white !important;
                text-decoration: none;
            }
            .bordered {
                border-inline: 2px solid var(--green-color);
                padding-inline: 2rem;
            }
            &__shirt {
                border: 1px solid var(--green-color);
                border-radius: 7px;
                padding: 1rem;
                display: flex;
                align-items: center;
                img {
                    width: 25px;
                    margin-left: 6px;
                    height: 25px;
                }
                a {
                    color: var(--green-color) !important;
                }
            }
            &__language {
                border: 1px solid white;
                background-color: var(--primary-color);
                border-radius: 7px;
                padding: 0.2rem;
                .dropdown-menu {
                    .dropdown-item {
                        justify-content: center;
                        color: var(--primary-color) !important;
                        width: 100%;
                        &.ACTIVE {
                            background-color: var(--primary-color);
                            color: white !important;
                        }
                    }
                }
                a {
                    align-items: center;
                    display: flex;
                    img {
                        height: 20px;
                        width: 20px;
                    }
                    span {
                        padding-inline: 0.4rem;
                    }
                }
            }
            &__social-media {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 0.4rem;

                svg {
                    width: 1rem;
                    height: 1rem;
                    background-color: white;
                    color: var(--primary-color);
                    border-radius: 6px;
                    padding: 5px;
                    cursor: pointer;
                }
            }
        }
    }
    footer {
        .social-media {
            svg {
                width: 1rem;
                height: 1rem;
            }
        }
    }
    .carousel-item {
        img {
            height: 30rem;
        }
    }
    #banner1 {
        // height: 558px;
        height: 30rem;
        background-size: cover;
    }
    #banner2 {
        height: 30rem;
        background-size: cover;
    }
}

@media all and (min-width: 1024px) {
    .header {
        justify-content: space-between;
        height: 7rem;
        &__logo {
            padding-inline: 3rem;
        }
        &__menu-large {
            width: 60%;
            a {
                font-size: 1.3rem;
            }
            &__language {
                a {
                    span {
                        font-size: 1.3rem;
                    }
                    img {
                        height: 25px;
                        width: 25px;
                    }
                }
            }
            &__shirt {
                img {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
    footer {
        padding: 4rem 2rem;
        .social-media {
            svg {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 8px;
            }
        }
        .article {
            p {
                font-size: calc(12px + 0.5vw);
            }
        }
    }
    .carousel-item {
        img {
            height: 35rem;
        }
    }
    #banner1 {
        // height: 558px;
        height: 35rem;
        background-size: cover;
    }
    #banner2 {
        height: 35rem;
        background-size: cover;
    }
}

@media all and (min-width: 1440px) {
    .header {
        height: calc(10rem);
        &__logo {
            padding-inline: calc(4rem + 1.5vw);
        }
        &__menu-large {
            width: 65%;
            a {
                font-size: calc(1.6rem + 0.4vw);
                padding-inline: 0.5rem;
            }
            .bordered {
                padding-inline: calc(2rem + 2vw);
                border-inline: 4px solid var(--green-color);
            }
            &__language {
                a {
                    span {
                        font-size: calc(1.6rem + 0.4vw);
                    }
                }
            }
            &__social-media {
                svg {
                    width: 1.7rem;
                    height: 1.7rem;
                }
            }
            &__shirt {
                img {
                    width: 65px;
                    height: 65px;
                }
            }
        }
    }
    footer {
        padding: 6rem 2rem;
        .article {
            margin-bottom: 0px;
            p {
                font-size: calc(12px + 0.3vw);
            }
            h6 {
                font-size: calc(1rem + 1.2vw);
                color: var(--green-color);
            }
        }
        .social-media {
            svg {
                width: 1.7rem;
                height: 1.7rem;
                margin-inline: 0.5rem;
                border-radius: 10px;
                padding: 10px;
            }
        }
        .logo {
            img {
                width: 60%;
            }
        }
    }
    .carousel-item {
        img {
            height: 35rem;
        }
    }
}
