.blog {
    &__header-image {
        background-color: var(--secondary-color);
        padding: 2rem;
        margin-bottom: 2rem;
    }
}
.entry-list {
    text-decoration: none;
    color: black;
    &__item-left {
        div {
            img {
                width: 100%;
                height: 180px;
                border-radius: 10px 0px 0px 10px;
            }
        }
        &__paragraph {
            border-radius: 0px 10px 10px 0px;
            padding-block: 1rem;
            padding-inline: 1rem !important;
            background-color: white;
            h4 {
                color: var(--primary-color);
                width: 70%;
                line-height: 1;
            }
            p {
                margin: 0px;
                line-height: 1;
                text-align: justify;
                font-size: 12px;
            }
        }
    }
    &__item-right {
        div {
            img {
                height: 180px;
                width: 100%;
                border-radius: 0px 10px 10px 0px;
            }
        }
        &__paragraph {
            border-radius: 10px 0px 0px 10px;
            padding-block: 1rem;
            padding-inline: 1rem !important;
            background-color: white;
            h4 {
                color: var(--primary-color);
                width: 70%;
                line-height: 1;
            }
            p {
                margin: 0px;
                line-height: 1;
                text-align: justify;
                font-size: 12px;
            }
        }
    }
    &__commentary {
        color: #706f6f;
        font-weight: 700;
        em {
            width: 80%;
        }
        span {
            width: 20%;
            text-align: center;
            svg {
                color: var(--primary-color);
                margin-left: 0.5rem;
            }
        }
    }
}

.entry-detail {
    min-height: 80vh;
    h1,
    h6 {
        color: var(--primary-color);
        text-align: center;
    }
    h1 {
        font-size: calc(2rem + 1.5vw);
        font-weight: bold;
        line-height: 1;
    }
    h6 span {
        font-weight: bold;
    }
    &__article {
        img {
            border-radius: 8px;
            padding: 0px;
            margin-bottom: 1rem;
        }
        p {
            line-height: 1;
            text-align: justify;
        }
    }
}

/* -------------------------------------------------------------------------------------------------- MEDIDAS RESPONSIVE ------------------------------------------------------------------------------------------------------ */
@media all and (min-width: 768px) {
    .blog {
        &__header-image {
            text-align: center;
            img {
                width: 80%;
            }
        }
    }
    .entry-list {
        &__item-left {
            div {
                img {
                    height: 250px;
                }
            }
        }
        &__item-right {
            div {
                img {
                    height: 250px;
                }
            }
        }
    }
    .entry-detail {
        h1,
        h6 {
            text-align: start;
        }
        h1 {
            font-size: calc(2rem + 1.5vw);
            font-weight: bold;
            line-height: 1;
        }
        h6 span {
            font-weight: bold;
        }
        &__article {
            column-count: 2;
            img {
                border-radius: 8px;
                padding: 0px;
                margin-bottom: 1rem;
            }
            p {
                line-height: 1;
                text-align: justify;
            }
        }
    }
}
@media all and (min-width: 1024px) {
    .blog {
        &__header-image {
            img {
                width: 70%;
            }
        }
    }
    .entry-list {
        &__item-left {
            div {
                img {
                    border-radius: 30px 0px 0px 30px;
                    height: 350px;
                }
            }
            &__paragraph {
                border-radius: 0px 30px 30px 0px;
                padding: 2rem !important;
                h4 {
                    width: 100%;
                    font-size: calc(2.6rem + 0.3vw);
                }
                p {
                    font-size: 1.3rem;
                }
            }
        }
        &__item-right {
            div {
                img {
                    border-radius: 0px 30px 30px 0px;
                    height: 350px;
                }
            }
            &__paragraph {
                border-radius: 30px 0px 0px 30px;
                padding: 2rem !important;
                h4 {
                    width: 100%;
                    font-size: calc(2.6rem + 0.3vw);
                }
                p {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
@media all and (min-width: 1440px) {
    .blog {
        &__header-image {
            img {
                width: 50%;
            }
        }
    }
    .entry-list {
        &__item-left {
            div {
                img {
                    height: 450px;
                }
            }
            &__paragraph {
                h4 {
                    font-size: calc(3rem + 0.3vw);
                }
                p {
                    font-size: 1.6rem;
                }
            }
        }
        &__item-right {
            div {
                img {
                    height: 450px;
                }
            }
            &__paragraph {
                h4 {
                    font-size: calc(3rem + 0.3vw);
                }
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }
}
