:root {
    --primary-color: #2d31fa;
    --secondary-color: #19267f;
    --third-color: #131930;
    --green-color: #4dffbf;
    --gray-color: #575756;
    --principal-background-color: #f1eff9;
}

@font-face {
    font-family: Helvetica;
    src: url(./../assets/fonts/Helvetica.ttf);
}

* {
    font-family: Helvetica;
    box-sizing: border-box;
    -webkit-appearance: none !important;
    font-size: 14px;
}

body {
    margin: 0;
    padding: 0;
    background: #f0f0f0;
    color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

main {
    background: var(--principal-background-color);
}
